
.cte-chart {
    position: relative;
    height: 400px;
    width: 100%;

    &--pie {
        margin-top: -10px !important;
    }
}
