
$font-size: 12px;

.waste-tracker-form {
    width: 1150px;
    margin: 0 auto;
    font-size: $font-size;

    .waste-tracker-form__fieldset {
        margin-bottom: 15px;

        &.waste-tracker-form__fieldset--collapsible {
            legend {
                cursor: pointer;
                position: relative;

                &:after {
                    position: absolute;
                    display: block;
                    font-family: 'FontAwesome', sans-serif;
                    text-align: center;
                    content: "\f077";
                    top: 5px;
                    font-size: 15px;
                    right: 5px;
                }
            }

            &.waste-tracker-form__fieldset--collapsed {
                legend:after {
                    content: "\f078";
                }
            }
        }
    }

    .waste-tracker__field--preset {
        border-color: #337ab7;
    }

    .form-group-sm select.form-control {
        font-size: $font-size;
    }

    .waste-tracker-form__line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .waste-tracker-form__line__frame {
            background-color: #f8f8f8;
            border-radius: .5rem;
            padding: .5rem;
            margin: .5rem;
            flex: 1;

            .form-group {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .waste-tracker-form__line__frame__field-composition {
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .waste-tracker-form__line__frame__field-composition__business {
                    width: 14%;

                    border-bottom: 1px solid #cccccc;
                    padding-bottom: 7px;
                }

                .waste-tracker-form__line__frame__field-composition__increment {
                    width: 6%;
                }

                .waste-tracker-form__line__frame__field-composition__client {
                    width: 19%;

                    border-bottom: 1px solid #cccccc;
                    padding-bottom: 7px;
                }

                .waste-tracker-form__line__frame__field-composition__place {
                    width: 24%;
                }

                .waste-tracker-form__line__frame__field-composition__substance {
                    width: 32%;
                }
            }

            .waste-tracker-form__line__frame__title {
                background-color: #fefefe;
                border-radius: 2rem;
                padding: 0 .5rem;
                color: #aaaaaa;
                border: 0;
                margin: 0;
                font-size: $font-size;
                width: auto;
            }

            .waste-tracker-form__line__frame__contractor {
                margin-bottom: 0;
            }
        }
    }

    .waste-tracker-form__tracking {
        display: flex;
        align-items: center;
        padding: .5rem;
        margin: .5rem;

        .waste-tracker-form__tracking__label-pva {
            margin: 0;
        }

        .waste-tracker-form__tracking__pva {
            width: 120px;
            margin: 0 5px;
        }

        .waste-tracker-form__tracking__label-date {
            margin: 0 0 0 2rem;
        }

        .waste-tracker-form__tracking__date {
            margin: 0 5px;
            width: 180px;
        }
    }
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
