
$done: #5cb85c;
$late: #f0ad4e;
$verylate: red;
$inactive: lightgray;

.cte-workflow {
    display: flex;
    align-items: center;
    font-size: .7rem;

    &__separator {
        color: $inactive;

        &--late {
            color: $late;
        }

        &--verylate {
            color: $verylate;
        }

        &--done {
            color: $done;
        }
    }

    &__state {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: $inactive;
        color: white;

        &--late {
            background: $late;
        }

        &--verylate {
            background: $verylate;
        }

        &--done {
            background: $done;
        }
    }
}
