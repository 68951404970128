
.cte-distribution {
    &--clickable {
        cursor: pointer;
    }

    &__title {
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .progress {
        background: none;
        margin-bottom: 0;
    }
}
