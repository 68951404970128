
.cte-stock-elements {
    table {
        font-size: 12px;
    }

    &__inactive {
        background: #dddddd;
    }

    &__row {
        transition: background 1s ease-out;

        input, select {
            border: 1px solid #dddddd;
        }

        &--saving {
            background: #d9edf7;

            input, select {
                background: #d9edf7;
            }
        }

        &--done, &--saved {
            background-color: #dff0d8;

            input, select {
                background: #dff0d8;
            }
        }
    }
}
