
.cte-chart-line {
    display: flex;
    justify-content: space-between;

    .cte-chart {
        max-width: 50%;
        margin: 1rem 0;
    }

    .cte-chart-nolib {
        width: 50%;
        margin: 1rem 0;

        &__title {
            color: #666666;
            text-align: center;
            font-weight: bold;
            font-size: .95rem;
        }

        &__more {
            color: #337ab7;
        }

        &__table {
            width: 100%;
            margin-top: 20px;

            &__supplier, &__amount, &__diff, &__progress {
                border: 1px solid lightgray;
                padding: 2px 5px;
                font-size: 12px;
            }

            &__amount, &__diff {
                text-align: right;
            }

            &__diff {
                width: 65px;
            }

            &__supplier {
                width: 210px;

                div {
                    width: 210px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &__amount {
                width: 90px;
            }

            .progress {
                height: 16px;
                margin: 0;
                min-width: 30px;
                box-shadow: none;
                border-radius: 0;
                background: none;
            }
        }
    }

    @media (max-width: 1000px) {
        flex-direction: column;

        .cte-chart {
            max-width: 100%;
        }

        .cte-chart-nolib {
            width: 100%;
        }
    }
}
