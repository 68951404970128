
.cte-stock-deliveries {
    font-size: 12px;

    &__date, &__place {
        font-weight: bold;
    }

    table {
        input, select {
            border: 1px solid #dddddd;
        }
    }

    &__row {
        height: 75px;

        &--unvaluable {
            background-color: #dddddd;

            input, select {
                background-color: #dddddd;
            }
        }

        &--ack {
            background-color: #dff0d8;

            input, select {
                background-color: #dff0d8;
            }
        }
    }
}
