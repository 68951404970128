
.cte-track-dechets {
    .form-group {
        margin-bottom: 8px;
    }

    legend {
        font-size: 1rem;
    }

    &__header {
        margin-bottom: 2rem;
        display: flex;

        &__workflow {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &__draft {
                margin: 1rem;
            }
        }
    }

    &--preset {
        input, select {
            border-color: #337ab7;
        }
    }
}
