
@import '~leaflet';

.cte-map {
    height: 300px;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    &--loading {
        animation: flickerAnimation 1s infinite;
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
