
.cte-doc-download {
    position: relative;

    &.btn i.fa-lock {
        top: 8px;
        left: 35px;
    }

    i.fa-lock {
        font-size: .8em;
        vertical-align: super;
        position: absolute;
        top: 0;
        left: 6px;
    }
}
