
.balance-form {
    &__extra {
        display: flex;
        align-items: center;

        &__part {
            margin: 0 5px;
        }

        input {
            width: 75px;
        }
    }
}

#clocking-balance .modal-header {
    padding: .5rem;

    .modal-title {
        font-weight: bold;
        text-align: center;
        font-size: 1.1rem;
    }
}
