
.waste-form {
    .waste-form__fieldset {
        margin-bottom: 15px;

        &.waste-form__fieldset--collapsible {
            legend {
                cursor: pointer;
                position: relative;

                &:after {
                    position: absolute;
                    display: block;
                    font-family: 'FontAwesome', sans-serif;
                    text-align: center;
                    content: "\f077";
                    top: 5px;
                    font-size: 15px;
                    right: 5px;
                }
            }

            &.waste-form__fieldset--collapsed {
                legend:after {
                    content: "\f078";
                }
            }
        }
    }
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
