
.business-form {
    .business-form__carbon {
        margin-bottom: 15px;

        .business-form__carbon__title {
            cursor: pointer;
            position: relative;

            &:after {
                position: absolute;
                display: block;
                font-family: 'FontAwesome', sans-serif;
                text-align: center;
                content: "\f077";
                top: 5px;
                font-size: 15px;
                right: 5px;
            }
        }

        &.business-form__carbon--collapsed {
            .business-form__carbon__title:after {
                content: "\f078";
            }
        }
    }
}

.business-entities {
    &__waste-tracker {
        &__label {
            min-width: 80px;
        }

        &__infos {
            margin: 0 .5rem;
            flex: 1;
        }
    }
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
