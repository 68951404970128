.building {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
  padding: .5rem;
  margin: .5rem 0;

  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &__title {
      font-size: 1.5rem;

      small {
        font-size: .75rem;
      }
    }
  }

  &__body {
    margin-top: 1rem;
  }
}
