
.cte-employee-review {
    &__section {
        border-top: 1px solid #eeeeee;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__pagetitle {
        display: none;
    }

    &__title {
        margin: 0 0 1rem;
        font-size: 1.1rem;
    }

    .cte-employee-review__section__collapser {
        cursor: pointer;
        position: relative;

        &:after {
            position: absolute;
            display: block;
            font-family: 'FontAwesome', sans-serif;
            text-align: center;
            content: "\f077";
            top: 5px;
            font-size: 15px;
            right: 5px;
        }

        &--collapsed:after {
            content: "\f078";
        }
    }

    th.cte-employee-review__title {
        font-weight: 500;
        font-size: 1.1rem;
    }

    table tr.qualification--current td,
    table tr td.qualification--soon {
        font-weight: bold;
    }

    &__more {
        a {
            text-align: center;
            display: block;
        }
    }

    &__activities {
        margin: 0 auto;

        td {
            width: 32px;
            height: 32px;

            &.activity-type-null {
                border: 1px solid #dddddd;
            }
        }
    }

    .progress-bar.activity-type-null {
        background: white;
        color: #000000;
    }

    .table > tbody > tr > td.sumable {
        text-align: right;
        width: 110px;
    }

    &__pay {
        legend {
            font-size: .9rem;
        }
    }

    &__sep {
        border-right: 2px solid #cccccc;
    }

    &__cat {
        background: #dddddd;
    }

    &__totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 160px;
        margin: auto;
        //font-size: 1.1rem;

        p {
            margin: 0
        }
    }

    @media print {
        font-family: 'Open Sans', sans-serif;
        font-size: 8pt;

        &__section {
            border: none;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        &__pagetitle {
            width: 100%;
            display: block;
            text-align: center;
            border: 1px solid #dddddd;
            padding: 5px 0;
            text-transform: uppercase;
            font-size: 12pt;
            margin: 0 0 10px;

            i {
                display: none !important;
            }
        }

        .btn,
        &__more,
        label,
        .col-xs-3,
        .cte-employee-review__section__collapser--collapsed,
        .cte-employee-review__section__collapser:after {
            display: none;
        }

        &__title {
            font-size: 12pt;
            margin: 7px 0 3px;
        }

        th.cte-employee-review__title {
            font-size: 12pt;
        }

        table > tr > th,
        table > thead > tr > th,
        table > tr > td,
        table > tbody > tr > td,
        table th,
        table td {
            margin: 0 !important;
            padding: 1px 4px !important;
            white-space: nowrap !important;

            &.work-description, &.qualification-name {
                overflow: hidden !important;
                text-overflow: ellipsis !important;
            }

            &.work-description {
                max-width: 220px !important;
            }

            &.qualification-name {
                max-width: 450px !important;
            }
        }

        table > tbody > tr.cte-employee-review__sub > th {
            font-weight: normal !important;
        }

        &__activities {
            td {
                border: 1px solid #dddddd;
            }
        }

        .col-md-6 {
            width: 50%;
        }

        .col-md-9 {
            width: 75%;
        }

        .col-md-3 {
            width: 25%;
        }

        .col-xs-9 {
            width: 100%;
            padding: 0;
            margin-top: 10px;
        }

        .cte-employee-review__trainings {
            &__inner, .cte-employee-review__title {
                padding: 0 5px;
            }
        }

        &__activities {
            margin: 0;

            td {
                width: 18px;
                height: 18px;
            }
        }
    }
}
