
.estimate-form_table {
    & > tbody > tr > td {
        vertical-align: top;
    }
}

.estimate-form_table-remove {
    &, &:visited, &:active {
        color: #000000;
        text-decoration: none;
    }
}

.btn-estimate_form {
    small {
        font-size: .9rem;
    }
}

.estimate-form_modal {
    @media (min-width: 768px) {
        .modal-dialog {
            min-width: 768px;
        }
    }

}
