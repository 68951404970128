
.cte-toggle {
    margin: 2px 0 -3px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__radio {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.5rem;

        input {
            margin: 0;
        }

        &__label {
            margin-left: .5rem;
        }
    }

    &--disabled {
        color: #aaaaaa;
        cursor: not-allowed;
    }

    &__label {
        margin-left: .5rem;
    }
}
