
.cte-stock {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    @media (max-width: 992px) {
        & {
            flex-direction: column;
        }
    }

    &--vehicle {
        padding-bottom: 1rem;
        border-bottom: 1px solid black;
    }

    &__header {
        background-color: #f8f8f8;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        @media (max-width: 992px) {
            & {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &__title {
            text-align: center;
            font-size: 1.5rem;
            line-height: 1.5;
            margin: 0;
        }

        &__icon {
            font-size: 24px;
        }
    }

    &__body {
        flex: 1;

        &__alert {
            margin: 0 -8px;
        }
    }

    &__division {
        border-right: 1px solid #777777;
        display: flex;
        flex-direction: column;
        padding: 0 .5rem;

        &--last {
            border: none;
        }

        @media (max-width: 992px) {
            & {
                border: none;
            }
        }

        &__header {
            background-color: #f8f8f8;
            text-align: center;

            &__title {
                margin: .5rem;
                font-size: 1.5rem;
            }

            &__subtitle {
                margin: .5rem;
                font-size: 1rem;
                color: #777777;
            }
        }
    }

    &__block {
        margin: 1rem;
        padding: 1rem;
        border: 1px solid;
        border-radius: 1rem;

        &--selectable:hover, &--selected {
            background: lightgray;
            cursor: pointer;
        }

        &__workplace {
            margin-top: 0.5rem;
        }

        &__workplace, &__wastetracker {
            font-size: .8rem;
        }
    }
}
