
.cte-contractor-form {
    &__row {
        &--preset {
            input, select {
                border-color: #337ab7;
            }
        }
    }
}
