
.cargo {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__hint {
      margin-top: .5rem;
      font-size: 0.9rem;
      color: #666;
    }
  }
}
