
.cte-selectable-filters {
    display: flex;

    &__title {
        margin-right: .5rem;
    }

    .btn:focus {
        background-color: #ffffff;
        border-color: #cccccc;
    }
}
