
.cte-track-dechets-form-section {
    border: 1px solid #dddddd;
    border-radius: .5rem;
    padding: .5rem 1rem;
    margin: 1rem 0;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &__title {
            font-variant: all-small-caps;
            font-size: 1.5rem;
            font-weight: bold;

            small {
                font-weight: normal;
            }
        }

        .cte-status {
            margin-right: .5rem;
        }
    }

    &__body {
        margin-top: 1rem;

        &__inner {
            display: flex;
            align-items: center;

            .col-xs-8 {
                border-right: 1px solid #e5e5e5;
            }
        }
    }

    &__sign {
    }
}
